import React from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaLinkedin,
  FaYoutube,
  FaTelegram,
} from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">        
      <h1 className="text-center"><a href="https://www.ecomspot.net" target="_blank">EcomSpot Market Networking &copy;  2024</a></h1>
      <p className="text-center">
              the premier online hub where businesses connect, collaborate, and
              thrive in the dynamic global marketplace of e-commerce.
            </p>
      <p className="text-center mt-3">
        <a href="https://www.ecomspot.net" target="_blank">About Us</a>
        |<Link to="#">Contact</Link>
        |<Link to="#">Privacy Policy</Link>
      </p>
      <div className="d-flex align-items-center gap-3 mt-2 mb-4">
      <a href="https://t.me/ecomspot" target="_blank" rel="noopener noreferrer">
        <FaTelegram className="text-primary fs-3 hover-icon" />
      </a>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <FaFacebook className="text-primary fs-3 hover-icon" />
      </a>
      <a href="https://youtube.com/@ecomspot?si=vRzchT6vTzeg_u-q" target="_blank" rel="noopener noreferrer">
        <FaYoutube className="text-primary fs-3 hover-icon" />
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
        <FaLinkedin className="text-primary fs-3 hover-icon" />
      </a>
      <a href="https://whatsapp.com/channel/0029VacBMA97z4kd9jkNdK2S" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="text-primary fs-3 hover-icon" />
      </a>
    </div>
      <div>pr401 systems architect</div>
    </div>
  );
};

export default Footer;