import React from 'react';

const Popover = ({ buyer, visible, position, onClose }) => {
  if (!visible) return null;

  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: '#ffefba',
        color: '#000',
        padding: '8px',      
        borderRadius: '5px',
        zIndex: 1,
        top: position.top,
        left: position.left,
        width: '250px',
        transform: 'translateY(5px)',
      }}
      onClick={onClose}
    >
      <p><strong>Name:</strong> {buyer?.name}</p>
      <p><strong>Email:</strong> {buyer?.email}</p>
      <p><strong>Phone:</strong> {buyer?.phone}</p>
      <p><strong>Address:</strong> {buyer?.address}</p>
    </div>
  );
};

export default Popover;
