import axios from 'axios';
const customAxios = axios.create({
  baseURL: 'https://ecommerce.ecomspot.net',
//   timeout: 1000,
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': 'Bearer YOUR_ACCESS_TOKEN'
//   }
});

export default customAxios;
