export const Prices = [
  {
    _id: 0,
    name: "0 to 999 Birr",
    array: [0, 999],
  }, 
  {
    _id: 1,
    name: "1,000 to 4,999 Birr",
    array: [1000, 4999],
  },
  {
    _id: 2,
    name: "5,000 to 9,999 Birr",
    array: [5000, 9999],
  },
  {
    _id: 3,
    name: "10,000 to 19,999 Birr",
    array: [10000, 19999],
  },
  {
    _id: 4,
    name: "20,000 to 49,999 Birr",
    array: [20000, 49999],
  },
  {
    _id: 5,
    name: "50,000 or more",
    array: [50000, 99999],
  },
];
